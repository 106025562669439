import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Facebook } from "react-content-loader";
import FooterApp from "../components/FooterApp";
import Navigation from "../components/Navigation";
import SideNavigation from "../components/SideNavigation";
import { isEmpty, separateNumbers } from "../utils";
import { getBooksRequest } from "../services/BookService";

const ManageBook = () => {
  const location = useLocation();
  const initialRender = useRef(false);
  const [loading, setLoading] = useState(true);
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBookData = async () => {
      await getBooks();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchBookData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBooks = async () => {
    const response = await getBooksRequest();

    if (response.status === "success") {
      setBooks(response.data);
    } else {
      setBooks([]);
      toast.warning(response.message);
    }

    setLoading(false);
  };

  return (
    <div className="container-scroller">
      <ToastContainer />
      <Navigation />

      <div className="container-fluid page-body-wrapper">
        <SideNavigation currentPage="manageBook" />

        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                    <h3 className="font-weight-bold">Gestion IM</h3>
                  </div>
                  {!loading && (
                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                          <a className="btn btn-success" href="/add/book">
                            Ajout nouveau IM
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <Facebook backgroundColor="#BBB" foregroundColor="#DDD" />
            ) : (
              <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Titre</th>
                              <th>Prix</th>
                              <th>Nombre de pages</th>
                              <th>Langue(s)</th>
                              <th>Disponible</th>
                              <th>Page</th>
                              <th>Modification</th>
                            </tr>
                          </thead>
                          <tbody>
                            {books.map((book) => {
                              return (
                                <tr key={book.id}>
                                  <td>{book.title}</td>
                                  {book.price === 0 ? (
                                    <td>Gratuit</td>
                                  ) : (
                                    <td>{separateNumbers(book.price)} Ar</td>
                                  )}
                                  <td>{book.pageCount}</td>
                                  {!isEmpty(book.languages) ? (
                                    <td>
                                      {book.languages.map(
                                        (language, index, languages) => {
                                          if (languages.length - 1 === index) {
                                            return language.name;
                                          } else {
                                            return language.name + " - ";
                                          }
                                        }
                                      )}
                                    </td>
                                  ) : (
                                    <td>Aucune</td>
                                  )}
                                  {!book.locked ? (
                                    <td className="text-success">Oui</td>
                                  ) : (
                                    <td className="text-danger">Non</td>
                                  )}
                                  <th>
                                    <NavLink
                                      className="btn btn-primary"
                                      to="/manage/page"
                                    >
                                      Voir les pages
                                    </NavLink>
                                  </th>
                                  <td>
                                    <NavLink
                                      className="nav-link btn btn-warning"
                                      to={`/manage/book/edit/${book.id}`}
                                    >
                                      <i className="ti-marker-alt text-white"></i>
                                      <span className="menu-title text-white">
                                        {" "}
                                        Modifier
                                      </span>
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterApp />
        </div>
      </div>
    </div>
  );
};

export default ManageBook;
