// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".priority-high {\n  z-index: 999;\n  position: relative; }\n\n.priority-low {\n  z-index: 1;\n  position: relative; }\n\n.sidebar .sidebar-button {\n  padding: 0.8125rem 1.937rem 0.8125rem 1rem;\n  border-radius: 8px; }\n\n.sidebar .active {\n  color: #6c7383; }\n\n.forms-sample .form__check--label {\n  margin-left: -1.75rem; }\n", "",{"version":3,"sources":["webpack://./src/styles/_setttings.scss","webpack://./src/styles/_buttons.scss","webpack://./src/styles/_forms.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB,EAAA;;AAGpB;EACE,UAAU;EACV,kBAAkB,EAAA;;ACPpB;EAEI,0CAA0C;EAC1C,kBAAkB,EAAA;;AAHtB;EAOI,cAAc,EAAA;;ACPlB;EAEI,qBAAqB,EAAA","sourcesContent":[".priority-high {\n  z-index: 999;\n  position: relative;\n}\n\n.priority-low {\n  z-index: 1;\n  position: relative;\n}\n",".sidebar {\n  .sidebar-button {\n    padding: 0.8125rem 1.937rem 0.8125rem 1rem;\n    border-radius: 8px;\n  }\n\n  .active {\n    color: #6c7383;\n  }\n}\n",".forms-sample {\n  .form__check--label {\n    margin-left: -1.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
